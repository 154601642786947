import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const InternationalIPResources = () => {

    return (
        <Layout>
            <Seo title="International IP Resources" />

            <h1 className="title">International IP Resources</h1>

            <SearchInput />

            <table>
                <tbody>
                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">IP legislation</td>
                    </tr>

                    <tr>
                        <td>Patent Cooperation Treaty (PCT)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wipo.int/pct/en/texts/articles/atoc.html">https://www.wipo.int/pct/en/texts/articles/atoc.html</a></td>
                    </tr>

                    <tr>
                        <td>PCT Applicant’s Guide</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wipo.int/pct/en/guide/index.html">https://www.wipo.int/pct/en/guide/index.html</a></td>
                    </tr>

                    <tr>
                        <td>Agreement on Trade-Related Aspects of Intellectual Property Rights (TRIPs)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wto.org/english/docs_e/legal_e/27-trips_01_e.htm">https://www.wto.org/english/docs_e/legal_e/27-trips_01_e.htm</a></td>
                    </tr>

                    <tr>
                        <td>Paris Convention for the Protection of Industrial Property</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wipo.int/treaties/en/ip/paris/">https://www.wipo.int/treaties/en/ip/paris/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">WIPO patent resources</td>
                    </tr>

                    <tr>
                        <td>WIPO log in</td>
                        <td><a target="_blank" rel="noreferrer" href="https://pct.wipo.int/authpage/signin.xhtml">https://pct.wipo.int/authpage/signin.xhtml</a></td>
                    </tr>

                    <tr>
                        <td>WIPO ePCT (filing and managing PCT applications)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://pct.wipo.int/ePCT/">https://pct.wipo.int/ePCT/</a></td>
                    </tr>

                    <tr>
                        <td>WIPO ePCT reference data lookup</td>
                        <td><a target="_blank" rel="noreferrer" href="https://pct.wipo.int/ePCTExternal/pages/home.xhtml">https://pct.wipo.int/ePCTExternal/pages/home.xhtml</a></td>
                    </tr>

                    <tr>
                        <td>WIPO Pat-informed database (international medicine patent information)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wipo.int/patinformed/">https://www.wipo.int/patinformed/</a></td>
                    </tr>

                    <tr>
                        <td>Foreign filing license list</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.wipo.int/pct/en/texts/nat_sec.html">https://www.wipo.int/pct/en/texts/nat_sec.html</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">WIPO trade mark resources</td>
                    </tr>

                    <tr>
                        <td>WIPO global brand database</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www3.wipo.int/branddb/en/">https://www3.wipo.int/branddb/en/</a></td>
                    </tr>

                    <tr>
                        <td>WIPO Madrid fee calculator</td>
                        <td><a target="_blank" rel="noreferrer" href="https://madrid.wipo.int/feecalcapp/">https://madrid.wipo.int/feecalcapp/</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Other resources</td>
                    </tr>

                    <tr>
                        <td>Patent offices outside the European Patent Organisation</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/service-support/useful-links/patent-offices.html">https://www.epo.org/service-support/useful-links/patent-offices.html</a></td>
                    </tr>

                    <tr>
                        <td>Black Hills IP (how to search international patent and trade mark office websites)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.blackhillsip.com/International-PTO-Web-Sites">https://www.blackhillsip.com/International-PTO-Web-Sites</a></td>
                    </tr>

                    <tr>
                        <td>Renewals Desk (IP renewals)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.renewalsdesk.com/">https://www.renewalsdesk.com/</a></td>
                    </tr>

                    <tr>
                        <td>Global Clinical trials</td>
                        <td><a target="_blank" rel="noreferrer" href="https://clinicaltrials.gov/">https://clinicaltrials.gov/</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default InternationalIPResources